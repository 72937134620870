import React, { MutableRefObject, useEffect, useRef } from "react";
import "./style.css";
import { gsap, Linear } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const HomeAbout = () => {
  const aboutRef: MutableRefObject<HTMLDivElement> = useRef(null);
  const targetSection: MutableRefObject<HTMLDivElement> = useRef(null);

  const initaboutAnimation = (
    aboutRef: MutableRefObject<HTMLDivElement>,
    targetSection: MutableRefObject<HTMLDivElement>
  ): ScrollTrigger => {
    const timeline = gsap.timeline({
      defaults: { ease: Linear.easeNone, duration: 0.1 },
    });
    timeline
      .fromTo(
        aboutRef.current.querySelector(".about__column-1"),
        { opacity: 0.2 },
        { opacity: 1 }
      )
      .to(aboutRef.current.querySelector(".about__column-1"), {
        opacity: 0.2,
        delay: 0.5,
      })
      .fromTo(
        aboutRef.current.querySelector(".about__column-2"),
        { opacity: 0.2 },
        { opacity: 1 }
        // "<"
      )
      .to(aboutRef.current.querySelector(".about__column-2"), {
        opacity: 0.2,
        delay: 1,
      })
      .fromTo(
        aboutRef.current.querySelector(".about__description"),
        { opacity: 0.2 },
        { opacity: 1 }
      )
      .to(aboutRef.current.querySelector(".about__description"), {
        opacity: 0.2,
        delay: 0.5,
      });

    const scrollTriggerInstance = ScrollTrigger.create({
      trigger: targetSection.current,
      start: "center 80%",
      end: "center top",
      scrub: 0,
      animation: timeline,
    });
    return scrollTriggerInstance;
  };

  useEffect(() => {
    const aboutScrollTriggerInstance = initaboutAnimation(
      aboutRef,
      targetSection
    );
    return aboutScrollTriggerInstance.kill;
  }, [aboutRef, targetSection]);

  const renderColumns = (): React.ReactNode => (
    <div className="about__container-content" ref={aboutRef}>
      <span className="about__column-1">
        I am an{" "}
        <strong className="text-pink-500">outcome-driven designer.</strong> My
        approach to design focuses on providing value into the hands of the
        customer while balancing company resources and business goals.{" "}
      </span>

      <span className="about__column-2">
        This doesn't mean out-of-this-world experiences are out of the question.
        Through customer research, testing and learning, I aim to push design to
        its limit.
      </span>
      <div className="about__description">
        Over the last 10 years, I have worked as a product, user experience, and
        user interface designer across different industries in Sydney. I’ve
        worked with clients such as <b>Apple</b>, <b>Kimberly-Clark</b>,{" "}
        <b>Bombay Sapphire</b> and <b>Mercedes-Benz</b>. In the last 5 years,
        I’ve worked as an in-house UX designer for Australia’s largest
        investment bank Macquarie, and currently as a UX designer for one of
        Australia’s largest life insurers, TAL.
      </div>
    </div>
  );

  return (
    <section className="about" ref={targetSection}>
      <div className="about__container-content">{renderColumns()}</div>
    </section>
  );
};

export default HomeAbout;
