import React from "react";
import "./style.css";
import Button from "components/atom/Button";
import { Link } from "gatsby";
import LoginButton from "components/atom/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";

export default function HomeCaseStudies({ forwardedRef }) {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <section className="case-studies" ref={forwardedRef}>
      <h3>Case studies</h3>
      <p>
        While I would love to share all the work I have done here, I have signed
        NDAs that prevent me from doing so. For a sampler of some of my works,
        you can view my Behance and my Dribbble. If you want to know more about
        me, my experience or my process, get in touch via LinkedIn and we can
        arrange a chat.
      </p>

      <p className="my-2">
        For those who have requested access to my case studies:
      </p>
      {isAuthenticated ? (
        <Link to="/projects">
          <Button variant="secondary">See Projects</Button>
        </Link>
      ) : (
        <LoginButton />
      )}
    </section>
  );
}
