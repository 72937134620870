import React, { useState, useEffect } from "react";
import { PageNavbar } from "components/layout/PageNavbar";
import { PageFooter } from "components/layout/PageFooter";
import { PageLayout } from "components/layout/PageLayout";
import HomeHero from "components/modules/HomeHero";
import HomeAbout from "components/modules/HomeAbout";
import { ProgressIndicator } from "components/block/ProgressIndicator";
import HomeTimeline from "components/modules/HomeTimeline";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HomeCaseStudies from "../components/modules/HomeCaseStudies";
import { HomeServices } from "../components/modules/HomeServices";
import HomeTest from "../components/modules/HomeTest";

const DEBOUNCE_TIME = 100;

// export const isSmallScreen = (): boolean => document.body.clientWidth < 767;

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

export const isSmallScreen = (): boolean => {
  if (isBrowser && document.body.clientWidth < 767) {
    return true;
  }
};

export interface IDesktop {
  isDesktop: boolean;
}

// markup
const Home = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.config({ nullTargetWarn: false });

  const [isDesktop, setisDesktop] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  let timer: NodeJS.Timeout = null;

  const debouncedDimensionCalculator = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const isDesktopResult =
        typeof window.orientation === "undefined" &&
        navigator.userAgent.indexOf("IEMobile") === -1;

      window.history.scrollRestoration = "manual";

      setisDesktop(isDesktopResult);
    }, DEBOUNCE_TIME);
  };
  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      return;
    }
  }, []);

  useEffect(() => {
    debouncedDimensionCalculator();

    window.addEventListener("resize", debouncedDimensionCalculator);
    return () =>
      window.removeEventListener("resize", debouncedDimensionCalculator);
  }, [timer]);

  const renderBackdrop = (): React.ReactNode => (
    <div className="fixed top-0 left-0 h-screen w-screen bg-gray-900 -z-1"></div>
  );
  return (
    <PageLayout>
      <HomeHero />
      <HomeAbout />
      {/* <HomeServices /> */}
      <HomeTimeline isDesktop={isDesktop} isSmallScreen={isSmallScreen} />
      <HomeCaseStudies />
    </PageLayout>
  );
};

export default Home;
