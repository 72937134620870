import React from "react";
import "./style.css";

import Loadable from "@loadable/component";
const LoadableTypewriter = Loadable(() => import("../../atom/TypingText/"));

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="hero__content-container">
        <h1 className="hero__text--headline">
          — Hi I'm Kerrie, I help{" "}
          <span>
            <LoadableTypewriter />
          </span>{" "}
          your ideas into{" "}
          <strong className="text-pink-500">user-centric</strong> products
        </h1>
        <div className="hero__text--subheadline">
          I am a seasoned designer with over 10 years of experience solving
          complex user problems.
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
