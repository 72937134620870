import { useStaticQuery, graphql } from "gatsby";

export enum Branch {
  LEFT = "leftSide",
  RIGHT = "rightSide",
}

export enum NodeTypes {
  CONVERGE = "converge",
  DIVERGE = "diverge",
  CHECKPOINT = "checkpoint",
}

export enum ItemSize {
  SMALL = "small",
  LARGE = "large",
}


export const TIMELINE: Array<TimelineNodeV2> = [
  {
    type: NodeTypes.CHECKPOINT,
    title: "Discover and Research",
    size: ItemSize.LARGE,
    shouldDrawLine: true,
    slideImage: "/static/9709f1aee9b3137a03dba78bf2d14c43/research.png",
    alignment: Branch.LEFT,
  },
  {
    type: NodeTypes.DIVERGE,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "🎯 Define the problem statement",
    subtitle: "I'll dive deep at the start by asking lots of questions about your project and the goals (and your budget and timeline) you and your stakeholders wish to achieve. Then, we'll work together to define the burning problem to solve.",
    size: ItemSize.SMALL,
    slideImage: "/static/9709f1aee9b3137a03dba78bf2d14c43/research.png",
    shouldDrawLine: true,
    alignment: Branch.RIGHT,
  },

  {
    type: NodeTypes.CHECKPOINT,
    title: "Who are your customers?",
    size: ItemSize.SMALL,
    subtitle:
      "We'll work together to gain insight into who your customers are and what they're goals are. We'll validate assumptions, come up with hypotheses, and uncover opportunities.",
    shouldDrawLine: true,
     slideImage: "/static/9709f1aee9b3137a03dba78bf2d14c43/research.png",
    alignment: Branch.RIGHT,
  },
  {
    type: NodeTypes.CONVERGE,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Strategy",
    size: ItemSize.LARGE,
    shouldDrawLine: true,
    slideImage: "/static/a458c50b55808b63670d5cd1c54d44fc/design.jpeg",
    alignment: Branch.LEFT,
  },
    {
    type: NodeTypes.DIVERGE,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Design possible solutions",
    size: ItemSize.SMALL,
    subtitle:
      "I'll analyse your existing process and tools and tailor each project to fit you and your users' needs. My background in design and research means I can help you every step along the way, starting from simple process diagrams to customer journey maps, wireframes, and high fidelity prototypes.",
    slideImage: "/static/1116dad81d6b254bf4fd59ad461aaf37/design-2.jpg",
    shouldDrawLine: true,
    alignment: Branch.RIGHT,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Test and Validate",
    size: ItemSize.SMALL,
    subtitle:
      "We'll test our solutions with real users before touching a single line of code.",
      slideImage: "/static/9709f1aee9b3137a03dba78bf2d14c43/research.png",
    shouldDrawLine: true,
    alignment: Branch.RIGHT,
  },
  {
    type: NodeTypes.CONVERGE,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Build",
    size: ItemSize.LARGE,
    shouldDrawLine: true,
    alignment: Branch.LEFT,
    slideImage: "/static/dd8e13d919ae0391e98c42f36cc1005c/build.jpeg",
  },
  
  {
    type: NodeTypes.DIVERGE,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Agile, scrum, get it done",
    subtitle:  "I have more than 5+ years of experience working inside and alongside agile teams. My goal is to eliminate the separation between design and development by speaking the same language (I'm a designer who can code!), help strengthen empathy and human-centered design capabilities, and to deliver innovative experiences.",
    slideImage: "/static/dd8e13d919ae0391e98c42f36cc1005c/build.jpeg",
    size: ItemSize.SMALL,
    shouldDrawLine: true,
    alignment: Branch.RIGHT,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Need to pivot?",
    subtitle:  "The beauty of agile is being able to adapt to circumstances. I can guide you through how we can respond while keeping within our agreed objectives and goals.",
    slideImage: "/static/dd8e13d919ae0391e98c42f36cc1005c/build.jpeg",
    size: ItemSize.SMALL,
    shouldDrawLine: true,
    alignment: Branch.RIGHT,
  },
  {
    type: NodeTypes.CONVERGE,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Iterate",
    size: ItemSize.LARGE,
    shouldDrawLine: true,
    slideImage: "/static/97e1f99c824bfd8d1439e0c01e1b6137/test.jpeg",
    alignment: Branch.LEFT,
  },
  {
    type: NodeTypes.CHECKPOINT,
    title: "Test & Learn",
    subtitle:  "Delivery isn't the final measure of success. Integrated into my process is a continual testing framework, using numbers to observe how well we've reached a project's objectives and whether there are areas for improvement.",
    slideImage: "/static/97e1f99c824bfd8d1439e0c01e1b6137/test.jpeg",
    size: ItemSize.SMALL,
    shouldDrawLine: true,
    alignment: Branch.LEFT,
  },

];

export type TimelineNodeV2 = CheckpointNode | BranchNode;

export interface CheckpointNode {
  type: NodeTypes.CHECKPOINT;
  title: string;
  subtitle?: string;
  size: ItemSize;
  image?: string;
  slideImage?: string;
  shouldDrawLine: boolean;
  alignment: Branch;
}

export interface BranchNode {
  type: NodeTypes.CONVERGE | NodeTypes.DIVERGE;
}
